.news-page {
  margin-bottom: 60px;

}

.news-page-wrapper {
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  justify-content: center;
  grid-gap: 30px;
  position: relative;
}

.news-page-see-more {
  position: absolute;
  font-weight: 700;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.news-page__card {
  border-radius: 20px;
  width: 300px;
  cursor: pointer;
}

.news-page__card-top {
  object-fit: cover;
  width: 300px;
  height: 300px;
}

.news-page__card-top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.news-page__card-bottom {
  background-color: #f7f7f7;
  height: 255px;
  padding: 38px 32px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.news-page__title {
  color: #191b1e;
  font-weight: 700;
  font-size: 28px;
}

.news-page__desc {
  color: #191b1e;
  font-weight: 400;
  font-size: 18px;
}

.news-card__createdAt{
  font-size: 14px;
  margin-bottom: 10px;
  color: #8e8e8e !important;
}

.current-news-page{
  margin-bottom: 60px;
}

.current-news-page__back-btn {
  cursor: pointer;
}

.current-news-page__current-news {
  margin-top: 25px;
  margin-bottom: 72px;
  display: flex;
  gap: 60px;
}

.current-news-page__current-news__title {
  color: #1a1a1a;
  font-weight: 700;
  font-size: 45px;
}

.current-news-page__current-news__description {
  color: #8e8e8e;
  font-size: 20px;
  font-weight: 400;
}

.current-news-page__current-news__img {
  width: 500px;
  height: 431px;
  object-fit: cover;
  
}

.current-news-page__bottom {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  justify-content: space-between;
  grid-gap: 10px;
}

.current-news-page__similar-news-title {
  color: #1f1f1f;
  font-weight: 500;
  font-size: 20px;
  cursor: default;
}


@media screen and (max-width: 970px) {
  .news-page-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
  }
}

@media screen and (max-width: 997px) {
  .current-news-page__current-news {
    flex-direction: column;
  }
}

@media screen and (max-width: 530px) {
  .current-news-page__current-news__img {
    width: 80%;
    height: auto;
  }
}
