@import url(https://fonts.googleapis.com/css2?family=Abel&family=Rajdhani&display=swap);
body {
    margin: 0;
    padding: 0;
    font-family: 'Abel', sans-serif;
    background-size: cover
}

.logo {
    width: 65px;
}

.parent-loading {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 12;
    background: rgba(0, 0, 0, 0.27);
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #ff6600;
    border-bottom: 16px solid #1a1a1a;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: absolute;
    left: 44%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

header {
    padding: 10px;
    background: white;
    box-shadow: -3px -3px 13px 0 #00000080;
}

header .col-lg-8 {
    text-align: right;
    margin-top: 8px;
}

header ul li {
    display: inline-block;
    margin-right: 12px;
}

header ul li a {
    padding: 7px;
    display: inline-block;
    border-radius: 3px;
    font-weight: bold;
    font-size: 21px;
    color: #ff6600 !important;
    min-width: 90px;
    cursor: pointer;
    text-align: center;
}

header ul li a:hover {
    transition: all ease 0.5s;
    background: #ff6600;
    color: #FFF !important;
    text-decoration: none;
}

footer {
    width: 100%;
    background: black;
    color: white;
    text-align: center;
    padding: 1px;
}

.row {
    margin-right: 0;
}

.gravity-right {
    text-align: right;
}

.aside-block h1 {
    color: #ff6600;
    margin-bottom: 13px;
}

.heading {
    color: #1a1a1a;
    margin-bottom: 13px;
    font-size: 30px;
}


.aside-block p {
    color: #c1c0c0;
    font-weight: bold;
}

.container {
    margin-top: 60px;
}

.col-lg-6.left-block {
    background: #f9f9f9;
    min-height: 400px;
    padding: 45px;
    border-radius: 11px;
}

.btn.btn-success {
    background: #ff6600;
    border-color: #ff6600;
    font-weight: bold;
    color: white;
    box-shadow: 3px 4px 7px 0 #ff572282;
    outline: none;
    /* margin-top: 1rem; */
}

.banner-cookie {
    position: fixed;
    z-index: 11;
    bottom: 0;
    left: 0;
    background: #2e1360;
    width: 100%;
    text-align: center;
    min-height: 90px;
}

.banner-cookie p {
    font-size: 27px;
    color: white;
    text-shadow: 2px 2px 5px black;
    margin-top: 20px;
}

p.price-not {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 6px;
    color: #F44336;
    font-size: 16px;
}

p.price-not span {}

.btn.btn-success:hover {
    box-shadow: none;
}

.btn.btn-success:active {
    background-color: #ff6600 !important;
    border-color: #ff6600 !important;
    font-weight: bold;
    outline-color: #3d3b39 !important;
    box-shadow: 3px 4px 7px 0 #ff572282;
}

a.bottom-link {
    /* position: absolute;
    bottom: 10px;
    width: 100%;
    display: inline-block;
    text-align: center;
    left: 0; */
}


.login-account__gravity {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.forgot-password_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.help {
    text-align: center;
}

.foxiptv {
    color: #ff6600;
    font-weight: bold;
}

.mini {
    display: inline-block;
    width: 114px;
    margin-right: 4px;
    text-align: center;
}

label {
    display: block;
}

.inline-select {
    display: inline-block;
    width: 130px;
}

.cvv-holder {
    text-align: right;
    margin-right: 0 !important;
    padding-right: 0 !important;
}

.row.device-item {
    background: #efefef;
    margin-bottom: 12px;
    padding: 18px;
    border-radius: 10px;
    transition: all ease 0.6s;
}

.open-block i {
    position: absolute;
    left: 90%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 24px;
    cursor: pointer;
}

.open-block i:hover {
    color: #ff6600;
}

.more-playlists {
    transition: all ease 0.6s;
    margin-top: 30px;
}

.row.playlist-item {
    background: white;
    margin-bottom: 10px;
    border-radius: 6px;
    padding: 14px;
    height: auto;
}

.action-btns {
    text-align: right;
}

.action-btns i {
    display: inline-block;
    margin-right: 10px;
    font-size: 21px;
    color: #CCC;
    cursor: pointer;
}

.action-btns i:hover {
    color: #ff6600;
    transition: all ease 0.5s;
}

.types {
    cursor: pointer;
}

.types:hover {
    cursor: pointer;
    background: #ce5503;
}

.active-type {
    background: #ff6600;
    color: white;
}

.type ul li {
    display: inline-block;
    width: 100px;
    text-align: center;
    border: 1px solid gray;
    font-weight: bold;
}

.type ul {
    padding-left: 0;
    margin-left: 0;
    margin-top: 60px;
}

.category-item {
    background: white;
    margin-bottom: 5px;
    padding: 7px;
    border-radius: 5px;
    transition: all ease 0.6s;
    cursor: pointer;
}

.category-item:hover {
    color: white;
    background: #d95802;
}

.active {
    background: #ff6600 !important;
    color: white;
}

.channel-item {
    /* background: #5f5f5f; */
    padding: 5px;
    border-radius: 5px;
    margin-top: 9px;
    color: #000000;
    font-weight: bold;
    border: 1px solid gray;
}

.channel-item img {
    width: 50px;
    height: 50px;
}

.channel-item .action-btns {
    margin-top: 10px;
}

.error-message {
    color: red;
}

.popup {
    background: url(/static/media/bg.2366a93d.jpg);
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.9;
    -webkit-filter: blur(8px);
            filter: blur(8px);
    z-index: 333;
}

.popup-content {
    width: 40%;
    background: white;
    min-height: 300px;
    padding: 10px;
    border-radius: 5px;
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    box-shadow: 1px 1px 20px 8px #504c4c2e;
    z-index: 333;
}


.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.rightInputBtn {
    /* position: absolute; */
    margin: 0;
}

p.country_item {
    text-align: left;
    background: #f9f9f9;
    padding: 7px;
    margin: 0;
    border-bottom: 1px solid #ced4da;
    cursor: pointer;
}

p.country_item:hover {
    background: #ff6600;
    color: white;
}

.PhoneInputInput {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}

p.separator:after {
    content: "";
    position: absolute;
    width: 43%;
    height: 1px;
    background: gray;
    -webkit-transform: translate(4%, -50%);
            transform: translate(4%, -50%);
    top: 50%;
}

p.separator:before {
    content: "";
    position: absolute;
    width: 43%;
    height: 1px;
    background: gray;
    -webkit-transform: translate(-105%, -50%);
            transform: translate(-105%, -50%);
    top: 50%;
}

p.separator {
    width: 100%;
    text-align: center;
    position: relative;
}

.bottom-form-block {
    text-align: center;
}

.add-epg_input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    outline: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


.add-epg_input:focus {
    border-color: #80bdff;
}

.submit-button_epg {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: auto;
}

.epg-url_label {
    margin: 0;
}

.item-list_main {
    margin-top: 2rem;
}

.item-list_main-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    /* width: 60%; */
}

.remove-epg_actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 8rem;
}

.are-you_sure {
    margin-top: 2rem;
}

.inputs_field {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup_wrapper {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    /* width: 95%; */
}

.btn-success-login {
    margin-top: 1rem;
    background: #ff6600;
    color: #fff;

}

@media screen and (max-width: 768px) {
    .popup-content {
        width: 80%;
    }
}

@media screen and (max-width: 576px) {
    .popup-content {
        width: 90%;
    }
}

.tab-btn {
    text-align: center;
    padding: 10px;
    background: #f9f9f9;
    margin-bottom: 10px;
    cursor: pointer;
    border: none;
    border-bottom: 2px solid #ced4da;
    outline: none !important;
    transition: all ease 0.3s;
}

.tab-btn.active-tab {
    /* background: #ff6600;
    color: white; */
    border-bottom: 2px solid #ff6600;
    outline: none !important;
}

    @media only screen and (max-width: 771px) {
    .device-logo {
        width: 30% !important;
    }

    .activate-btn {
        top: -8rem;
    }
    
    .mobile-arrow-down {
        margin-top: -4rem !important;
    }

    .new-playlist-btn {
        margin-right: -1.8rem;
    }

    .devices-main {
        margin-top: -2.5rem;
    }
}
span.salary {
    margin-left: 6px;
    font-size: 15px;
}
.add.button-next {
    width: auto !important;
    background: #28a745;
}
span{
    color: #ff6600;
}
b{
    font-weight: bolder;
    font-size: 22px;
}
.news-page {
  margin-bottom: 60px;

}

.news-page-wrapper {
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  justify-content: center;
  grid-gap: 30px;
  position: relative;
}

.news-page-see-more {
  position: absolute;
  font-weight: 700;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.news-page__card {
  border-radius: 20px;
  width: 300px;
  cursor: pointer;
}

.news-page__card-top {
  object-fit: cover;
  width: 300px;
  height: 300px;
}

.news-page__card-top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.news-page__card-bottom {
  background-color: #f7f7f7;
  height: 255px;
  padding: 38px 32px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.news-page__title {
  color: #191b1e;
  font-weight: 700;
  font-size: 28px;
}

.news-page__desc {
  color: #191b1e;
  font-weight: 400;
  font-size: 18px;
}

.news-card__createdAt{
  font-size: 14px;
  margin-bottom: 10px;
  color: #8e8e8e !important;
}

.current-news-page{
  margin-bottom: 60px;
}

.current-news-page__back-btn {
  cursor: pointer;
}

.current-news-page__current-news {
  margin-top: 25px;
  margin-bottom: 72px;
  display: flex;
  grid-gap: 60px;
  gap: 60px;
}

.current-news-page__current-news__title {
  color: #1a1a1a;
  font-weight: 700;
  font-size: 45px;
}

.current-news-page__current-news__description {
  color: #8e8e8e;
  font-size: 20px;
  font-weight: 400;
}

.current-news-page__current-news__img {
  width: 500px;
  height: 431px;
  object-fit: cover;
  
}

.current-news-page__bottom {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  justify-content: space-between;
  grid-gap: 10px;
}

.current-news-page__similar-news-title {
  color: #1f1f1f;
  font-weight: 500;
  font-size: 20px;
  cursor: default;
}


@media screen and (max-width: 970px) {
  .news-page-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
  }
}

@media screen and (max-width: 997px) {
  .current-news-page__current-news {
    flex-direction: column;
  }
}

@media screen and (max-width: 530px) {
  .current-news-page__current-news__img {
    width: 80%;
    height: auto;
  }
}

